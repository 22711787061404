import React, { FC, useContext } from "react";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { TeaserProps } from "../generated-types";
import { Box, Flex, Heading } from "@chakra-ui/react";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { imageSizes, themable } from "~/foundation/Theme";
import { getLinkVariants } from "~/foundation/Theme/components/Link";
import LinkIconWrapper from "~/foundation/Components/Link/LinkIconWrapper";
import { ChakraRouterLink } from "~/foundation/Components/Link/ChakraRouterLink";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";

const LargeTeaser: FC<TeaserProps> = ({ rendering, fields }) => {
	const { sitecoreContext } = useSitecoreContext();
	const isRtl = sitecoreContext.custom.settings.isRtl;
	const linkVariants = getLinkVariants(isRtl);
	const [theme] = useContext(ColorThemeContext);

	return (
		<Ribbon position="relative">
			{(fields?.teaserImage?.value || sitecoreContext.pageEditing) &&
				<Box position="absolute" top="0" bottom="0" left="0" right="0" sx={{
					'.LazyLoad': {
						height: "100%",
						width: "100%",
						'&.is-visible': {
							'&:after': {
								content: `""`,
								position: 'absolute',
								top: "0",
								opacity: ".6",
								bgColor: 'primary.text',
								h: '100%',
								w: '100%',
							}
						}
					}
				}}>
					<sc.AdvanceImage
						field={fields?.teaserImage}
						itemId={rendering.dataSource}
						fieldNames={["teaser image"]}
						srcSet={[imageSizes.largeTeaser.square.sm, imageSizes.largeTeaser.square.md, imageSizes.largeTeaser.landscape.sm, imageSizes.largeTeaser.landscape.md, imageSizes.largeTeaser.landscape.lg, imageSizes.largeTeaser.landscape.xl, imageSizes.largeTeaser.landscape.xxl]}
						sx={{ objectPosition: "center center", objectFit: "cover", width: "100%", height: "100%" }}
					/>
				</Box>
			}
			<ChakraRouterLink
				variant="unstyled"
				as={(!sitecoreContext.pageEditing && fields?.teaserLink?.value.href) ? "a" : "span"}
				display="block"
				{...((!sitecoreContext.pageEditing && fields?.teaserLink?.value.href) && {
					to: fields?.teaserLink?.value?.href,
					isExternal: fields?.teaserLink?.value?.linktype === "external" ? true : false,
				})}
			>
				<ContentWrapper py={["10", null, "4.75rem"]} px={["12", null, "24"]} position="relative" zIndex="1">
					<Flex px={["4", null, "0"]} flexFlow="column" alignItems="center" justifyContent="center">
						{(fields?.teaserHeadline?.value && !sitecoreContext.pageEditing) &&
							<Heading
								as="h3"
								size="md"
								textAlign="center"
								color={fields?.teaserImage?.value?.src ? "primary.white" : theme.colors.ribbon.color}
								dangerouslySetInnerHTML={{ __html: fields.teaserHeadline.value }} />
						}
						{sitecoreContext.pageEditing &&
							<Heading as="h2" size="md" mb={["3", null, "4"]}>
								<sc.Text field={fields?.teaserHeadline}/>
							</Heading>
						}
						{sitecoreContext.pageEditing
							?
							<Box py="5">
								<sc.Link field={fields?.teaserLink} variant="secondary" size="lg" color={fields?.teaserImage?.value?.src ? "primary.white" : theme.colors.ribbon.linkColor} />
							</Box>
							:
							<>
								{fields?.teaserLink?.value.href &&
									<ChakraRouterLink as="div" variant="secondary" size="lg">
										<Box sx={linkVariants.secondary} pt="5" color={fields?.teaserImage?.value?.src ? "primary.white" : theme.colors.ribbon.linkColor}>
											<LinkIconWrapper linkValue={fields?.teaserLink.value} w="icons.lg" rtl={isRtl} me="5"/>
											<span>{fields?.teaserLink.value.text ? fields?.teaserLink.value.text : fields?.teaserLink.value.href}</span>
										</Box>
									</ChakraRouterLink>
								}
							</>
						}
					</Flex>
				</ContentWrapper>
			</ChakraRouterLink>
		</Ribbon>
	);
};

export default themable()(LargeTeaser);